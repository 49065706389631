import axios from "../axios";

export const setAllMentors = (data) => {
  return {
    type: "SET_ALL_MENTORS",
    payload: data,
  };
};

export const asyncGetAllMentors = (query) => {
  return (dispatch) => {
    const url = `/team/allmembers`;
    const config = {
      params: query,
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        dispatch(setAllMentors(data));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};
