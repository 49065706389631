const initialState = {
  userIpInfo: {},
  allCurrency: [],
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_USER_IP_INFO":
      return {
        ...state,
        userIpInfo: payload,
      };
    case "SET_ALL_CURRENCY":
      return {
        ...state,
        allCurrency: payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;
