const initialState = {
  allJobCategories: [],
  allJobs: [],
  currentJob: {},
  countryData: [],
};

const jobReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_ALL_JOB_CATEGORY":
      return {
        ...state,
        allJobCategories: payload,
      };
    case "SET_ALL_JOBS":
      return {
        ...state,
        allJobs: payload,
      };
    case "SET_JOB_DATA":
      return {
        ...state,
        currentJob: payload,
      };
    case "SET_ALL_COUNTRIES":
      return {
        ...state,
        countryData: payload,
      };
    default:
      return state;
  }
};

export default jobReducer;
