const initialState = {
  alltestimonials: [],
  allInstructorReviews: [],
  allPlacedTestimonial: [],
};

const testimonialReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_ALL_TESTIMONIALS":
      return {
        ...state,
        alltestimonials: payload,
      };
    case "SET_ALL_Instrutor_REVIEWS":
      return {
        ...state,
        allInstructorReviews: payload,
      };
    case "SET_ALL_PLACED_TESTIMONIAL":
      return {
        ...state,
        allPlacedTestimonial: payload,
      };
    default:
      return state;
  }
};

export default testimonialReducer;
