import axios from "../axios";
import { notifyDanger } from "./customFn";

export const setAllTestimonials = (data) => {
  return {
    type: "SET_ALL_TESTIMONIALS",
    payload: data,
  };
};

export const asyncGetAllTestimonials = () => {
  return (dispatch) => {
    const url = "/testimonial/view";
    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        dispatch(setAllTestimonials(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};



export const setAllInstructorReviews = (data) => {
  return {
    type: "SET_ALL_Instrutor_REVIEWS",
    payload: data,
  };
};

export const asyncGetAllInstructorReview = () => {
  return (dispatch) => {
    const url = `/instructor/review-get/`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        dispatch(setAllInstructorReviews(data));
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
        console.log(err.response);

      });
  };
};


export const setAllPlacedTestimonial = (data) => {
  return {
    type: "SET_ALL_PLACED_TESTIMONIAL",
    payload: data,
  };
};

export const asyncGetAllPlacedTestimonial = () => {
  return (dispatch) => {
    const url = `/page/placed_student/get`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        dispatch(setAllPlacedTestimonial(data));
      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "some error occured");
        console.log(err.response);
      });
  };
};
