import React, { useState } from "react";
import { BellIcon } from "../../asset/icons/Icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import MiddleLoader from "../MiddleLoader";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
const DashboardGraph = ({ setGraphPeriod, isLoading, profileData }) => {
  const courseAnalytics = useSelector(
    (state) => state.batchData.courseAnalytics
  );

  const [dayDetails, setDayDetails] = useState({
    day: "",
    year: "",
    month: "",
    weekday: "",
  });

  const getMonthYearDay = () => {
    const date = new Date();
    const month = date.toLocaleDateString("en-GB", { month: "long" });
    const year = date.getFullYear();
    const weekday = date.toLocaleDateString("en-GB", { weekday: "long" });
    const day = date.toLocaleDateString("en-GB", {day:"2-digit" });
    setDayDetails({ month, year, day, weekday });
  };

  useEffect(() => {
    getMonthYearDay();
  }, []);

  return (
    <div className="dashboard_graph_container">
      <div className="dashboard_graph_info">
        <div className="dashboard_graph_date">
          <h2>{dayDetails.weekday}</h2>
          <h3>
            {dayDetails.month} {dayDetails.day}, {dayDetails.year}
          </h3>
        </div>
        <div className="dashboard_graph_profile">
          {/* <BellIcon /> */}
          <NavLink to={"/dashboard/profile"}>
            <img alt="" src={profileData.image} />
          </NavLink>
        </div>
      </div>
      <div className="graph_heading">
        <h4>Study Statistics</h4>
        <select onChange={(e) => setGraphPeriod(e.target.value)}>
          <option value="weekly">weekly</option>
          <option value="thismonth">this month</option>
          <option value="sixMonths">last six months</option>
        </select>
      </div>
      {!isLoading && Object.keys(courseAnalytics).length > 0 ? (
        <Line options={options} data={courseAnalytics} />
      ) : (
        <MiddleLoader />
      )}
    </div>
  );
};

export default DashboardGraph;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Line Chart",
    // },
  },
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return value + " min";
        },
      },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Data Science",
      data: [0, 5, 10, 15, 20, 25, 30],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Digital Marketing",
      data: [0, 12, 18, 24, 30, 32, 40],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
