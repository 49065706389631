const initialState = {
  allmasterclass: [],
};

const masterclassReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "SET_ALL_MASTERCLASS":
      return {
        ...state,
        allmasterclass: payload,
      };
    default:
      return state;
  }
};
export default masterclassReducer;
