const initialState = {
  notification: false,
};

const ticketReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_TICKETS_NOTIFICATION":
      return {
        ...state,
        notification: payload,
      };
    default:
      return state;
  }
};

export const setTicketNotification = (value) => {
  return {
    type: "GET_TICKETS_NOTIFICATION",
    payload: value,
  };
};

export default ticketReducer;
