const initialState = {
  allprograms: [],
  programData: {},
  allcourses: [],
  paymentData: {},
  previewModule: {},
  totalCourses: [],
  isCourseCheck: false,
};

const courseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_ALL_PROGRAMS":
      return {
        ...state,
        allprograms: payload,
      };
    case "SET_PROGRAM_DATA":
      return {
        ...state,
        programData: payload,
      };
    case "SET_ALL_COURSES":
      return {
        ...state,
        allcourses: payload,
      };
    case "SET_COURSE_DATA":
      return {
        ...state,
        paymentData: payload,
      };
    case "SET_ALL_PREVIEW_VIDEOS":
      return {
        ...state,
        previewModule: payload,
      };
    case "SET_ALL_TOTAL_COURSES":
      return {
        ...state,
        totalCourses: payload,
      };
    case "SET_COURSE_CHECK":
      return {
        ...state,
        isCourseCheck: payload,
      };
    default:
      return state;
  }
};

export default courseReducer;
