const initialState = {
  blogDesktopSidebar: false,
  blogMobileSidebar: false,
  blogArr: [],
  blogContent: {},
  allSubcategory: [],
  blogquery: null,
};

export const blogReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "TOGGLE_BUTTON":
      return {
        ...state,
        blogDesktopSidebar: !state.blogDesktopSidebar,
      };
    case "TOGGLE_BUTTON_MOBILE":
      return {
        ...state,
        blogMobileSidebar: !state.blogMobileSidebar,
      };
    case "SET_MY_BLOGS":
      return {
        ...state,
        blogArr: payload,
      };
    case "SET_BLOG_DATA":
      return {
        ...state,
        blogContent: payload,
      };
    case "SET_SUB_CATEGORY":
      return {
        ...state,
        allSubcategory: payload,
      };
    case "SET_BLOG_SEARCH":
      return {
        ...state,
        blogquery: payload,
      };
    default:
      return state;
  }
};
