import React from "react";
import { ThreeDots } from "react-loader-spinner";

const MiddleLoader = ({ Height, customHeight }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ height: Height ? "70vh" : customHeight || "40vh" }}
    >
      <ThreeDots
        height="80"
        width="80"
        color="#1171ef"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default MiddleLoader;
